import React from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import AuthHeader from 'components/Global/Elements/AuthHeader/AuthHeader'
import AccountWrapper from 'components/Account/AccountWrapper/AccountWrapper'
import { Outlet, useLocation } from 'react-router-dom'
import SideBar from 'components/Global/Layout/SideBar/SideBar'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useEffect } from 'react'
import { useState } from 'react'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
function WebsiteWrapper() {
  const {t} =useTranslation()
  const location = useLocation()
  const [isSideBarVisible , setIsSideBarVisible] =useState(false)
  const [isNavbarHide,setIsNavbarHide] =useState(false)
  const [isNavbarFixed,setIsNavbarFixed] =useState(false)
  const [products,setAllProducts] =useState(false)
  let toggleSideNavBar =(type)=>{
    setIsSideBarVisible(type==='open')
    if(type==='open'){
      document.querySelector('.js-bottom-navbar').classList.add('hidden-bottom-navbar')
    }else{
      document.querySelector('.js-bottom-navbar').classList.remove('hidden-bottom-navbar')
    }
  }
  

  useEffect(()=>{
    function toggleNavbarWhileScroll(){
      if(window.scrollY >80 ){
        setIsNavbarFixed(true)
      }else{
        setIsNavbarFixed(false)
      }
    }
    let windowScroll =window.addEventListener('scroll',toggleNavbarWhileScroll)
    return ()=>window.removeEventListener('scroll',windowScroll)
  },[])
  return (
    <>
    {/* <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        <MetaTags>
            <title>{t('Account -RD.Aroma')}</title>
        </MetaTags> */}
        {
          !location.pathname.includes('receive-order')&&
          <>
          <NavBar 
            toggleSideNavBar={()=>{toggleSideNavBar('open')}}
            isNavbarFixed={isNavbarFixed} 
            isNavbarHide={isNavbarHide}
          />
          <SideBar isSideBarVisible={isSideBarVisible} toggleSideNavBar={()=>{toggleSideNavBar('close')}}/>
          </>
        }
        <Outlet></Outlet>
        {
          !location.pathname.includes('receive-order')&&
          <Footer/>
        }
    {/* </motion.div> */}
    </>
  )
}

export default WebsiteWrapper