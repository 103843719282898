import SuccessAlert from 'components/Global/Elements/Alerts/SuccessAlert/SuccessAlert'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { axiosConfig } from 'utils/axiosConfig'
import styles from './MyGeneratedWebsites.module.css'
function MyGeneratedWebsites() {
    const {t} = useTranslation()
    const [orders ,setOrders] = useState([])
    const [isCancelled ,setIsCancelled] = useState(false)
    const [isError ,setIsError] = useState(false)
    const [isErrorMessage ,setIsErrorMessage] = useState('')
    const selector = useSelector(state=>state?.GlobalReducer)

    function getItem(){
      axiosConfig.get('/website-builder/my-website-builders',{
        headers:{
          authorization:`Bearer ${selector?.token}`
        }
      }).then(res=>{
        setOrders(res?.data?.data)
      }).catch(err=>{
        console.log(err)
      })
    }
    function cancelOrder(id){
      axiosConfig.get(`/website-builder/my-website-builders`,{status:5,order_id:id},{
        headers:{
          authorization:`Bearer ${selector?.token}`
        }
      }).then(res=>{
        setIsCancelled(true)
        setIsError(false)
        getItem()
        // setOrders(res?.data?.data)
      }).catch(err=>{
        setIsCancelled(false)
        setIsError(true)
        setIsErrorMessage(err?.respose?.data?.message)
        console.log(err)
      })
    }

    useEffect(()=>{
      getItem()
    },[])
    

  return (
    <section className={styles['orders-wrapper']}>
      {
        isCancelled && <SuccessAlert message={t('Order Cancelled Successfully')}/>
      }
      {
        isError && <SuccessAlert message={isErrorMessage}/>
      }
      <Table responsive borderless className={styles['order__table']} size="lg">
      <thead className='border-bottom'>
        <tr>
          <th>{t('ORDER')}</th>
          <th>{t('Name')}</th>
          <th>{t('Domain')}</th>
          <th>{t('Design Template')}</th>
          <th>{t('ACTIONS')}</th>
        </tr>
      </thead>
      <tbody>
        {
          orders && orders.map(order=>(
            <tr>
              <td>
                <Link to={`${order?.id}`} className={styles['orders__link-text']}>#{order?.item_number}</Link>
              </td>
              <td>
                <label className={styles['orders__link-date']}>{order?.Website_name}</label>
              </td>
              <td>
                <label className={styles['orders__link-date']}>{order?.Website_domain_name}</label>
              </td>
              <td>
                <label className={styles['orders__link-date']}>{order?.design_template}</label>
              </td>
              
              <td>
                <div className={styles['orders__link-wrapper']}>

                  <Link to={`/generate-website/website-configuration/${order?.Website_domain_name}`} className={styles['orders__link']}>{t('website configuration')}</Link>
                  <Link to={`/preview-website/${order?.Website_domain_name}`} className={styles['orders__link']}>{t('VIEW')}</Link>
                  <Link to={`/generate-website/website-information/${order?.Website_domain_name}`} className={styles['orders__link']}>{t('Update')}</Link>

                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
    </section>
  )
}

export default MyGeneratedWebsites