import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Container } from 'react-bootstrap'
import { axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import styles from './GetStarted.module.css'
import { Link } from 'react-router-dom'

function GetStarted() {
  const {t} =useTranslation()
  const[settings,setSettings] =useState([])
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      >
        <MetaTags>
              <title>{t('Terms -RD.Aroma')}</title>
        </MetaTags>
        <section className='my-5'>
          <Container>
            <h1 className={styles['page__header']}>Welcome To Ecommerce Generator</h1>
            <Link to='website-information' className={styles['page__link']}>
              Create New Website
            </Link>
              {/* <div className={styles['terms']}
              dangerouslySetInnerHTML={{__html: settings?.terms_and_conditionds}}/> */}
          </Container>
      </section>
    </motion.div>
    </>
  )
}

export default GetStarted