import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Col, Container } from 'react-bootstrap'
import { BASE_URL, axiosConfig } from 'utils/axiosConfig'
import { useSelector } from 'react-redux'
import styles from './AddUpdateWebsiteInformation.module.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify'

function AddUpdateWebsiteInformation() {
  const {t} =useTranslation()
  const[websiteOwnerName,setWebsiteOwnerName] =useState('')
  const[websiteOwnerPhone,setWebsiteOwnerPhone] =useState('')
  const[websiteName,setWebsiteName] =useState('')
  const[websitePhone,setWebsitePhone] =useState('')
  const[websiteWhatsappPhone,setWebsiteWhatsappPhone] =useState('')
  const[websiteDomainName,setWebsiteDomainName] =useState('')
  const[designTemplate,setDesignTemplate] =useState('')
  const[domain,setDomain] =useState('')
  const[image,setImage]=useState([])
  const [data,setData] =useState({})
  const[isLoginLoading,setIsLoginLoading] =useState(false)
  const selector = useSelector(state=>state?.GlobalReducer)
  const params = useParams()
  const navigate = useNavigate()
  
  async function handleUploadedImage(e){
    let image = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        e.target.files[0],
        400,
        400,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri)
          resolve(uri);
        },
        "file",
        400,
        400,
      );
    });
      Object.assign(image, {
        preview: URL.createObjectURL(image),
    })
    setImage(image)
  }

  function getWebsite(){
    axiosConfig.get(`/website-builder/single-website-builder/${params?.domain}`).then(res=>{
      setData(res.data.data)
      console.log('res.data.data',res.data.data)
      // setIsLoaded(true)
    }).catch(error=>{
      console.log(error.response)
    })
  }
  useEffect(()=>{
    setWebsiteOwnerName(data?.Website_owner_name)
    setWebsiteOwnerPhone(data?.Website_owner_phone)
    setWebsiteName(data?.Website_name)
    setWebsitePhone(data?.Website_phone)
    setWebsiteWhatsappPhone(data?.Website_whatsapp_phone)
    setWebsiteDomainName(data?.Website_domain_name)
    setDesignTemplate(data?.design_template)
    setImage(data?.image)
  },[data])
  function createWebsite(){
    
    let formData = new FormData()
    formData?.append('Website_owner_name',websiteOwnerName)
    formData?.append('Website_owner_phone',websiteOwnerPhone)
    formData?.append('Website_name',websiteName)
    formData?.append('Website_phone',websitePhone)
    formData?.append('Website_whatsapp_phone',websiteWhatsappPhone)
    formData?.append('Website_domain_name',websiteDomainName)
    formData?.append('design_template',designTemplate)
    formData?.append('image',image)
    setIsLoginLoading(true)
    
    if(params?.domain){
      axiosConfig.put(`/website-builder/update-website-builder/${data?.id}`,formData,{
        headers: {
            authorization:`Bearer ${selector?.token}`
        }
    }).then(res=>{
      setIsLoginLoading(false)
      setDomain(res.data.data?.domain)
      toast.success('website Updated successfully')
      navigate('/my-account/my-generated-websites')
    }).catch(err=>{
      setIsLoginLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/website-builder/create-website-builder`,formData,{
        headers: {
            authorization:`Bearer ${selector?.token}`
        }
    }).then(res=>{
      setIsLoginLoading(false)
      setDomain(res.data.data?.domain)
      toast.success('website created successfully')
      navigate('/my-account/my-generated-websites')
      }).catch(err=>{
        setIsLoginLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  useEffect(()=>{
    getWebsite()
  },[])
  
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      >
        <MetaTags>
              <title>{t('Terms -RD.Aroma')}</title>
        </MetaTags>
        <section className='my-5'>
          <Container>
            <h1 className={styles['page__header']}>Please Enter Website Information</h1>
            <div className={styles['form__input-wrapper']}>
              <input 
                type='text'
                className={styles['form__input']}
                placeholder="website Owner name"
                value={websiteOwnerName}
                onChange={e=>setWebsiteOwnerName(e.target.value)}
              />
              <input 
                type='text'
                className={styles['form__input']}
                placeholder="website Owner Phone"
                value={websiteOwnerPhone}
                onChange={e=>setWebsiteOwnerPhone(e.target.value)}
              />
              <input 
                type='text'
                className={styles['form__input']}
                placeholder="website name"
                value={websiteName}
                onChange={e=>setWebsiteName(e.target.value)}
              />
              <input 
                type='text'
                className={styles['form__input']}
                placeholder="website Phone"
                value={websitePhone}
                onChange={e=>setWebsitePhone(e.target.value)}
              />
              <input 
                type='text'
                className={styles['form__input']}
                placeholder="website Whatsapp Phone"
                value={websiteWhatsappPhone}
                onChange={e=>setWebsiteWhatsappPhone(e.target.value)}
              />
              <input 
                type='text'
                className={styles['form__input']}
                placeholder="website Domain Name"
                value={websiteDomainName}
                onChange={e=>setWebsiteDomainName(e.target.value)}
              />
              <select
                className={styles['form__input']}
                value={designTemplate}
                onChange={e=>setDesignTemplate(e.target.value)}
              >
                <option value=''>Please Select Template</option>
                <option value='1'>First Template</option>
                <option value='2'>Second Template</option>

              </select>
              
            <Col sm='12'>
                <div>
                    <label className='fs-5 text-muted mb-0 mt-5' htmlFor='carImage'>{t('Logo')}</label>
                    <div>
                    <input
                        type='file'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid mb-5 ps-14'
                        placeholder='Type Icon'
                        id='carImage'
                        onChange={(e) => handleUploadedImage(e)}
                    />
                    </div>
                    {
                        image&&(image?.preview||image?.length!=0)&&
                            <div>
                                <div className='mb-5 d-flex align-items-center justify-content-between'>
                                    <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                                    <button onClick={()=>{setImage(null)}}
                                    className='btn btn-danger ms-auto'>{t('Delete')}</button>
                                </div>
                            </div>
                    }
                </div>
            </Col>
            </div>
            <button onClick={createWebsite} className={styles['page__link']} diasbled={isLoginLoading}>
              {isLoginLoading?'Creating....':'Create New Website'}
            </button>
            {domain&&<Link to={`/preview-website/${domain}`} >{domain}</Link>}
            
              {/* <div className={styles['terms']}
              dangerouslySetInnerHTML={{__html: settings?.terms_and_conditionds}}/> */}
          </Container>
      </section>
    </motion.div>
    </>
  )
}

export default AddUpdateWebsiteInformation