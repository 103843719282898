import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import AuthHeader from 'components/Global/Elements/AuthHeader/AuthHeader'
import ResetPasswordForm from 'components/ResetPassword/ResetPasswordForm/ResetPasswordForm'
import { useParams } from 'react-router-dom'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import FirstTemplate from '../FirstTemplate/FirstTemplate'
import SecondTemplate from '../SecondTemplate/SecondTemplate'
// import ResetPasswordForm from 'components/ForgetPassword/ForgetPasswordForm/ForgetPasswordForm'
function PreviewTemplate() {
  const {t} =useTranslation()
  const {domain} = useParams()
  const [item,setItem] =useState(null)
  const [isLoaded,setIsLoaded]=useState(false)
  
  function getWebsite(){
    axiosConfig.get(`/website-builder/single-website-builder/${domain}`).then(res=>{
      setItem(res.data.data)
      console.log('res.data.data',res.data.data)
      setIsLoaded(true)
    }).catch(error=>{
      console.log(error.response)
    })
  }
  useEffect(()=>{
    getWebsite()
  },[])
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        <MetaTags>
              <title>{t('Login -RD.Aroma')}</title>
        </MetaTags>
        <Container>
          
          <h2>Website_owner_name:{item?.Website_owner_name}<hr/></h2>
          <h2>Website_owner_phone:{item?.Website_owner_phone}<hr/></h2>
          <h2>Website_name:{item?.Website_name}<hr/></h2>
          <h2>Website_phone:{item?.Website_phone}<hr/></h2>
          <h2>Website_domain_name:{item?.Website_domain_name}<hr/></h2>
          <h2>Website_whatsapp_phone:{item?.Website_whatsapp_phone}<hr/></h2>
          <h2>design_template:{item?.design_template}<hr/></h2>
          <h2>Logo:<img src={item?.image} width='100' height='100'/><hr/></h2>
          {
            item?.categories?.map(category=>(
              <>
                <h2>
                  Categories ::
                  {category?.title} -
                  <img src={category?.image} width='100' height='100'/>
                </h2>
                {
                  category?.products?.map(product=>(
                    <>Product:--  <img src={product?.image} width='100' height='100'/>
                    {product?.title} -- {product?.description} -- {product?.price_before_discount} --{product?.price_after_discount}</>
                  ))
                }
                <hr/>
              </>
            ))
          }
            
          
          
        </Container>
        {item?.design_template ==1 &&<FirstTemplate/>}
        {item?.design_template ==2 &&<SecondTemplate/>}
        
        
        
        
        
        
        
        {/* <AuthHeader/>
        <ResetPasswordForm/> */}
    </motion.div>
    </>
  )
}

export default PreviewTemplate