import React from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import AuthHeader from 'components/Global/Elements/AuthHeader/AuthHeader'
import ResetPasswordForm from 'components/ResetPassword/ResetPasswordForm/ResetPasswordForm'
import FirstTemplateNavbar from 'components/Templates/FirstTemplate/FirstTemplateNavbar/FirstTemplateNavbar'
import FirstTemplateFooter from 'components/Templates/FirstTemplate/FirstTemplateFooter/FirstTemplateFooter'
// import ResetPasswordForm from 'components/ForgetPassword/ForgetPasswordForm/ForgetPasswordForm'
function FirstTemplate() {
  const {t} =useTranslation()
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        <MetaTags>
            <title>{t('Login -RD.Aroma')}</title>
        </MetaTags>
        <FirstTemplateNavbar/>
        <FirstTemplateFooter/>
        <h1>First Navbar</h1>
          
        <h1>First Footer</h1>
      
    </motion.div>
    </>
  )
}

export default FirstTemplate