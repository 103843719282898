import SuccessAlert from 'components/Global/Elements/Alerts/SuccessAlert/SuccessAlert'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { axiosConfig } from 'utils/axiosConfig'
import styles from './WebsiteConfigurationCategoryProducts.module.css'
function WebsiteConfigurationCategoryProducts() {
    const {t} = useTranslation()
    const [isCancelled ,setIsCancelled] = useState(false)
    const [isError ,setIsError] = useState(false)
    const [isErrorMessage ,setIsErrorMessage] = useState('')
    // const selector = useSelector(state=>state?.GlobalReducer)
    const {domain ,category_id} = useParams()
    const [items,setItems] =useState([])

    function getWebsite(){
      axiosConfig.get(`/website-builder/single-website-builder/${domain}`).then(res=>{
        let category = res.data.data?.categories?.filter(category=>{
          return category_id ==category?.id
        })?.[0]
        setItems(category?.products)
        console.log('res.data.data',category)
        // setIsLoaded(true)
      }).catch(error=>{
        console.log(error.response)
      })
    }
    useEffect(()=>{
      getWebsite()
    },[])

  return (
    <section className={styles['orders-wrapper']}>
      {
        isCancelled && <SuccessAlert message={t('Order Cancelled Successfully')}/>
      }
      {
        isError && <SuccessAlert message={isErrorMessage}/>
      }
      <Link to='add-product'>Add Product</Link>
      <Table responsive borderless className={styles['order__table']} size="lg">
      <thead className='border-bottom'>
        <tr>
          <th>{t('Product')}</th>
          <th>{t('Image')}</th>
          <th>{t('Title')}</th>
          <th>{t('Products')}</th>
          {/* <th>{t('PAYMENT STATUS')}</th> */}
          {/* <th>{t('TOTAL')}</th> */}
          {/* <th>{t('Invoice')}</th> */}
          <th>{t('ACTIONS')}</th>
        </tr>
      </thead>
      <tbody>
        {
          items && items.map((item,index)=>(
            <tr>
              <td>
                <Link to={`${item?.id}`} className={styles['orders__link-text']}>#{index +1}</Link>
              </td>
              <td>
                <img src={item?.image} width={'100'} height='100' />
              </td>
              <td>
                <label className={styles['orders__link-date']}>{item?.title}</label>
              </td>
              {/* <td>
                <label className={styles['orders__link-date']}>{item?.product}</label>
              </td> */}
              {/* <td>
                <Link to={`${item?.id}`} className={styles['orders__link']}>{t('Products')}</Link>
              </td> */}
              <td>
                <div className={styles['orders__link-wrapper']}>
                
                  <Link to={`update-product/${item?.id}`} className={styles['orders__link']}>{t('Update')}</Link>
                  {/* <button className={styles['orders__link']} onClick={()=>{cancelOrder(order?.id)}}>{t('Delete')}</button> */}
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
    </section>
  )
}

export default WebsiteConfigurationCategoryProducts