import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './WebsiteConfigurationAddUpdateCategory.module.css'
import { Link, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import { emailPattern, phonePattern } from 'utils/features'
import { axiosConfig } from 'utils/axiosConfig'
import LoadingElement from 'components/Global/Elements/LoadingElement/LoadingElement'
import SuccessAlert from 'components/Global/Elements/Alerts/SuccessAlert/SuccessAlert'
import FailureAlert from 'components/Global/Elements/Alerts/FailureAlert/FailureAlert'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Resizer from "react-image-file-resizer";

function WebsiteConfigurationAddUpdateCategory() {
    const {t} = useTranslation()
    const [isLoading,setISloading]=useState('')
    const [isSubmittedBefore,setIsSubmittedBefore] = useState(false)
    const [data,setData]=useState({title:'',description:'',streetAddress:'',state:''})
    const [errors,setErros]=useState({})
    const selector = useSelector(state=>state?.GlobalReducer)
    const [isSuccess,setIsSuccess] = useState(false)
    const [isError,setIsError] = useState(false)
    const [successMessage,setSuccessMessage] = useState('')
    const [errorMessage,setErrorMessage] = useState('')
    const [item,setItem] =useState([])
    const[image,setImage]=useState([])
    const params=useParams()
  function validateInputs(modififedData){
      let status =true
      let errors ={}
      if(!modififedData?.title){
          errors = {...errors,title:t('Name Is Required')}
          status=false
      }
      if(!modififedData?.description){
          errors = {...errors,description:t('description Is Required')}
          status=false
      }
      if(!modififedData?.streetAddress){
          errors = {...errors,streetAddress:t('Street Address Is Required')}
          status=false
      }
      if(!phonePattern.test(modififedData?.phone)){
        errors = {...errors,phone:t('Phone Must Start With 971')}
        status=false
      }
      setErros(errors)
      return status
  }

  function handleChange(value , name){
      let modififedData = {...data}
      modififedData[name] = value
      
      if(isSubmittedBefore){
          validateInputs(modififedData)
      }
      setData(modififedData)
  }
  
  function handleSubmit(){
    setIsSubmittedBefore(true)
    // if(!validateInputs(data)){
    //     return
    // }
    setISloading(true)
    const formData= new FormData()
    formData.append('title',data?.title)
    formData.append('description',data?.description)
    formData.append('image',image)
    if(params?.category_id){
      formData.append('updated_item_id',params?.category_id)
      axiosConfig.put(`/website-builder/update-category-in-website/${params?.domain}`,formData,{
        headers:{
          authorization:`Bearer ${selector?.token}`
        }
      }).then(res=>{
          setISloading(false)
          setIsSuccess(true)
          setIsError(false)
          setSuccessMessage(res?.data?.message)
        }).catch(err=>{
          setISloading(false)
          setIsSuccess(false)
          setIsError(true)
          setErrorMessage(err?.response?.data?.message)
      })

    }else{

      axiosConfig.post(`/website-builder/add-category-to-website/${params?.domain}`,formData,{
        headers:{
          authorization:`Bearer ${selector?.token}`
        }
      }).then(res=>{
          setISloading(false)
          setIsSuccess(true)
          setIsError(false)
          setSuccessMessage(res?.data?.message)
        }).catch(err=>{
          setISloading(false)
          setIsSuccess(false)
          setIsError(true)
          setErrorMessage(err?.response?.data?.message)
      })
    }

  }

  useEffect(()=>{
    setData({
      title:item?.title,
      description:item?.description,
    })
    setImage(item?.image)
  },[item])

  function getCategory(){
    axiosConfig.get(`/website-builder/category-of-website/${params?.domain}/${params?.category_id}`).then(res=>{
      setItem(res.data.data)
      console.log('res.data.data',res.data.data)
      // setIsLoaded(true)
    }).catch(error=>{
      console.log(error.response)
    })
  }
  useEffect(()=>{
    if(params?.category_id){
      getCategory()
    }
  },[])

  async function handleUploadedImage(e){
    let image = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        e.target.files[0],
        400,
        400,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri)
          resolve(uri);
        },
        "file",
        400,
        400,
      );
    });
      Object.assign(image, {
        preview: URL.createObjectURL(image),
    })
    setImage(image)
  }
  return (
    <section className={styles['add-item__wrapper']}>
      <h1 className={styles['add-item__title']}>{t('ADD Update Category')}</h1>
      {
        isSuccess&& <SuccessAlert message={successMessage}/>
      }
      {
        isError&& <FailureAlert message={errorMessage}/>
      }
      <form className={styles['add-item__form']}>
        <div className={styles['add-item__input-wrapper']}>
          <label className={styles['add-item__input-label']} htmlFor='title'>{t('Title')} *</label>
          <input 
            type='text' 
            className={styles['add-item__input']} 
            id='title'
            name='title'
            value={data?.title}
            onChange={(e)=>{handleChange(e.target.value,e.target.name)}}
          />
          {errors?.title && <p className={styles['add-item__input-error']} >{errors?.title}</p>}
        </div>

        <div className={styles['add-item__input-wrapper']}>
          <label className={styles['add-item__input-label']} htmlFor='description'>{t('Description')} *</label>
          <input 
            type='text' 
            className={styles['add-item__input']} 
            id='description'
            name='description'
            value={data?.description}
            onChange={(e)=>{handleChange(e.target.value,e.target.name)}}
          />
          {errors?.description && <p className={styles['add-item__input-error']} >{errors?.description}</p>}
        </div>
        
        <Col sm='12'>
          <div className={styles['add-item__input-wrapper']}>
              <label className='fs-5 text-muted mb-0 mt-5' htmlFor='carImage'>{t('Image')}</label>
              <div>
              <input
                  type='file'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid mb-5 ps-14'
                  placeholder='Type Icon'
                  id='carImage'
                  onChange={(e) => handleUploadedImage(e)}
              />
              </div>
              {
                  image&&(image?.preview||image?.length!=0)&&
                      <div>
                          <div className='mb-5 d-flex align-items-center justify-content-between'>
                              <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                              <button onClick={()=>{setImage(null)}}
                              className='btn btn-danger ms-auto'>{t('Delete')}</button>
                          </div>
                      </div>
              }
          </div>
        </Col>


        <button className={styles['add-item__submit-button']} type='button' onClick={handleSubmit} disabled={isLoading}>
          {
            isLoading ?
            <LoadingElement/>
            :
          t('SAVE ADDRESS')
          }
        </button>

      </form>
    </section>
  )
}

export default WebsiteConfigurationAddUpdateCategory