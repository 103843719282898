import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './WebsiteConfiguration.module.css'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import {ReactComponent as OrdersIcon} from 'assets/icons/ordersIcon.svg'
import {ReactComponent as AddressessIcon} from 'assets/icons/addressessIcon.svg'
import {ReactComponent as ProfileIcon} from 'assets/icons/profileIcon.svg'
import {ReactComponent as RightArrowIcon} from 'assets/icons/rightArrow.svg'
import {ReactComponent as LeftArrowIcon} from 'assets/icons/leftArrow.svg'
import Resizer from "react-image-file-resizer";
import { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import { useRef } from 'react'
import { useCallback } from 'react'
import ProductItemCard from 'components/Global/Elements/ProductItemCard/ProductItemCard'
import { useSelector } from 'react-redux'
function WebsiteConfiguration() {
    const {t} = useTranslation()
    const selector = useSelector(state=>state?.GlobalReducer)
   
  return (
    <section className={styles['dashboard-wrapper']}>
      {/* <Container> */}
        <h2 className={styles['dashboard__title']}>{t('Welcome to your account page')}</h2>
        <p className={styles['dashboard__description']}>{t('Hi')} <span className={styles['dashboard__description-user']}>{selector?.user?.display_name}</span>, {t('today is a great day to check your account page. You can check also:')}</p>
        <div className={styles['dashboard__other-links-wrapper']}>
          <Link to='categories' className={styles['dashboard__other-link']}>
            <OrdersIcon className={styles['dashboard__other-link-icon']}/>
            {t('Categories')}
          </Link>
          {/* <Link to='addresses' className={styles['dashboard__other-link']}>
            <AddressessIcon className={styles['dashboard__other-link-icon']}/>
            {t('Addresses')}
          </Link>
          <Link to='edit-account' className={styles['dashboard__other-link']}>
            <ProfileIcon className={styles['dashboard__other-link-icon']}/>
            {t('Account details')}
          </Link> */}
        </div>
    </section>
  )
}

export default WebsiteConfiguration